/* eslint-disable */
/* prettier-ignore */
import * as Types from '@health/queries/dist/graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddBulkSubListItemsMutationVariables = Types.Exact<{
  subListId: Types.Scalars['ID'];
  subListItems: Array<Types.SubListItemInput> | Types.SubListItemInput;
}>;


export type AddBulkSubListItemsMutation = { __typename?: 'Mutation', addBulkSubListItems?: { __typename: 'SubList', id?: string | null, display?: string | null, field?: { __typename?: 'Field', id?: string | null, code?: string | null, display?: string | null } | null, errors?: Array<{ __typename?: 'DecisionMakerGraphqlError', field?: string | null, code?: Types.DecisionMakerErrorCodes | null, message?: string | null } | null> | null } | null };


export const AddBulkSubListItemsDocument = gql`
    mutation addBulkSubListItems($subListId: ID!, $subListItems: [SubListItemInput!]!) {
  addBulkSubListItems(subListId: $subListId, subListItems: $subListItems) {
    id
    display
    field {
      id
      code
      display
    }
    errors {
      field
      code
      message
    }
    __typename
  }
}
    `;
export type AddBulkSubListItemsMutationFn = Apollo.MutationFunction<AddBulkSubListItemsMutation, AddBulkSubListItemsMutationVariables>;

/**
 * __useAddBulkSubListItemsMutation__
 *
 * To run a mutation, you first call `useAddBulkSubListItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBulkSubListItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBulkSubListItemsMutation, { data, loading, error }] = useAddBulkSubListItemsMutation({
 *   variables: {
 *      subListId: // value for 'subListId'
 *      subListItems: // value for 'subListItems'
 *   },
 * });
 */
export function useAddBulkSubListItemsMutation(baseOptions?: Apollo.MutationHookOptions<AddBulkSubListItemsMutation, AddBulkSubListItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBulkSubListItemsMutation, AddBulkSubListItemsMutationVariables>(AddBulkSubListItemsDocument, options);
      }
export type AddBulkSubListItemsMutationHookResult = ReturnType<typeof useAddBulkSubListItemsMutation>;
export type AddBulkSubListItemsMutationResult = Apollo.MutationResult<AddBulkSubListItemsMutation>;
export type AddBulkSubListItemsMutationOptions = Apollo.BaseMutationOptions<AddBulkSubListItemsMutation, AddBulkSubListItemsMutationVariables>;