import { i18next } from "@health/i18n";
import { PrescriptionOrderBulkFileRecord, PrescriptionOrderRecordsOrderDirection, PrescriptionOrderRecordSortField } from "@health/queries";
import { CustomTableColumnProps, format } from "@health/ui";

type ColumnProps = {
  t: i18next.TFunction;
};

// Helper function to get column value by code
const getColumnValue = (row, code) => {
  const column = row.columns.find(col => col.code === code);
  return column ? column.valueString : null;
};

export const getBulkUploadFileColumns = ({ t }: ColumnProps): CustomTableColumnProps<PrescriptionOrderBulkFileRecord>[] => [
  {
    key: "erxReference",
    header: t("eRx Reference"),
    accessor: row => getColumnValue(row, "erxReference"),
  },
  {
    key: "nationalId",
    header: t("National ID"),
    accessor: row => getColumnValue(row, "nationalId"),
  },
  {
    key: "branchLicence",
    header: t("Branch License"),
    accessor: row => getColumnValue(row, "branchLicence"),
  },
  {
    key: "orderType",
    header: t("Order Type"),
    accessor: row => getColumnValue(row, "orderType"),
  },
  {
    key: "ticketId",
    header: t("Ticket ID"),
    accessor: row => getColumnValue(row, "ticketId"),
  },
  {
    key: "dropOffCity",
    header: t("Drop Off City"),
    accessor: row => getColumnValue(row, "dropOffCity"),
  },
  {
    key: "dropOffArea",
    header: t("Drop Off Area"),
    accessor: row => getColumnValue(row, "dropOffArea"),
  },
  {
    key: "processingStatus",
    header: t("Processing Status"),
    accessor: row => t(row.processingStatus ?? "UNKNOWN"),
  },
  {
    key: "failureReason",
    header: t("Failure Reason"),
    accessor: row => row.failureReason || t("N/A"),
  },
  {
    key: "createdDate",
    header: t("Creation date"),
    accessor: row => format(row.createdDate, "dd/MM/yyyy hh:mm a"),
    isSortable: true,
    sortDirection: PrescriptionOrderRecordsOrderDirection.Desc,
    sortColumnEnum: PrescriptionOrderRecordSortField.CreatedDate,
  },
  {
    key: "updatedDate",
    header: t("Update Date"),
    accessor: row => format(row.updatedDate, "dd/MM/yyyy hh:mm a"),
    isSortable: true,
    sortDirection: PrescriptionOrderRecordsOrderDirection.Desc,
    sortColumnEnum: PrescriptionOrderRecordSortField.UpdatedDate,
  },
];
