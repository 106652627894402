/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkFilesQueryVariables = Types.Exact<{
  filters: Types.BulkFilesFilterInput;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.BulkFilesSortingInput>;
}>;


export type BulkFilesQuery = { __typename?: 'Query', bulkFiles?: { __typename?: 'BulkFileConnection', totalCount?: number | null, edges?: Array<{ __typename?: 'BulkFileEdge', cursor?: string | null, node?: { __typename?: 'BulkFile', createdDate?: any | null, failedRecordCount?: number | null, id?: string | null, processedRecordCount?: number | null, recordsCount?: number | null, processingStatus?: Types.BulkProcessingStatus | null, updatedDate?: any | null, createdBy?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, id: string } | null, errors?: Array<{ __typename?: 'BulkGraphqlError', code?: Types.BulkErrorCodes | null, errorType?: Types.ErrorType | null, field?: string | null, message?: string | null } | null> | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const BulkFilesDocument = gql`
    query BulkFiles($filters: BulkFilesFilterInput!, $first: Int, $after: String, $before: String, $last: Int, $sortBy: BulkFilesSortingInput) {
  bulkFiles(
    filters: $filters
    first: $first
    last: $last
    after: $after
    before: $before
    sortBy: $sortBy
  ) {
    totalCount
    edges {
      cursor
      node {
        createdDate
        failedRecordCount
        id
        processedRecordCount
        recordsCount
        processingStatus
        updatedDate
        createdBy {
          firstName
          lastName
          id
        }
        errors {
          code
          errorType
          field
          message
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useBulkFilesQuery__
 *
 * To run a query within a React component, call `useBulkFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkFilesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useBulkFilesQuery(baseOptions: Apollo.QueryHookOptions<BulkFilesQuery, BulkFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BulkFilesQuery, BulkFilesQueryVariables>(BulkFilesDocument, options);
      }
export function useBulkFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BulkFilesQuery, BulkFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BulkFilesQuery, BulkFilesQueryVariables>(BulkFilesDocument, options);
        }
export type BulkFilesQueryHookResult = ReturnType<typeof useBulkFilesQuery>;
export type BulkFilesLazyQueryHookResult = ReturnType<typeof useBulkFilesLazyQuery>;
export type BulkFilesQueryResult = Apollo.QueryResult<BulkFilesQuery, BulkFilesQueryVariables>;