/* eslint-disable react/no-unknown-property */
/* eslint-disable react/forbid-dom-props */
/* eslint-disable max-lines */
import React, { FC } from "react";
// import { makeStyles } from "tss-react/mui";
import { adminLayoutVariables } from "../../customization/layout";

// const useStyles = makeStyles()(() => ({
//   st0: { fill: "#1C2346" },
//   st1: { fill: "url(#SVGID_1_)" },
//   st2: { fill: "url(#SVGID_00000042727515863602394500000000273361957468045238_)" },
//   st3: { fill: "url(#SVGID_00000165208532490012510980000013433950246074644155_)" },
//   st4: { fill: "url(#SVGID_00000132068979637457343280000008431883264524295555_)" },
// }));
export const YusurLogo: FC<{ width?: string | number; height?: string | number }> = ({ width, height }) => {
  // const { classes } = useStyles();
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      id='svg2'
      width={width ? width : "60"}
      height={height ? height : "60"}
      version='1.1'
      viewBox='0 0 748.439 317.24'
    >
      <defs id='defs6'>
        <linearGradient
          id='linearGradient30'
          x1='0'
          x2='1'
          y1='0'
          y2='0'
          gradientTransform='scale(118.0091 -118.0091)rotate(-31.193 .443 -6.364)'
          gradientUnits='userSpaceOnUse'
          spreadMethod='pad'
        >
          <stop id='stop24' offset='0' stopColor='#653995' stopOpacity='1'></stop>
          <stop id='stop26' offset='0.035' stopColor='#653995' stopOpacity='1'></stop>
          <stop id='stop28' offset='1' stopColor='#f16622' stopOpacity='1'></stop>
        </linearGradient>
        <clipPath id='clipPath40' clipPathUnits='userSpaceOnUse'>
          <path id='path38' d='M0 237.93h561.329V0H0Z'></path>
        </clipPath>
      </defs>
      <g id='g10' transform='matrix(1.33333 0 0 -1.33333 0 317.24)'>
        <g id='g12'>
          <g id='g14'>
            <g id='g20'>
              <g id='g22'>
                <path
                  id='path32'
                  fill='url(#linearGradient30)'
                  stroke='none'
                  d='m458.41 202.23-65.239-37.665a6.76 6.76 0 0 1-3.379-5.854v-75.36a6.76 6.76 0 0 1 3.379-5.854l65.24-37.666a6.76 6.76 0 0 1 6.759 0l65.239 37.666a6.76 6.76 0 0 1 3.379 5.854v26.499l-68.08-39.317-3.917-2.251-45.69 26.367v52.764l72.057 41.538-22.988 13.278a6.764 6.764 0 0 1-6.76.001'
                ></path>
              </g>
            </g>
          </g>
        </g>
        <g id='g34'>
          <g id='g36' clipPath='url(#clipPath40)'>
            <g id='g42' transform='translate(533.789 158.71)'>
              <path
                id='path44'
                fill='#f16622'
                fillOpacity='1'
                fillRule='nonzero'
                stroke='none'
                d='M0 0v-35.122l-26.309-15.2v39.024L-60.042 8.171l26.309 15.201L-3.381 5.854A6.76 6.76 0 0 0 0 0'
              ></path>
            </g>
            <g id='g46' transform='translate(299.793 75.564)'>
              <path
                id='path48'
                fill='#142248'
                fillOpacity='1'
                fillRule='nonzero'
                stroke='none'
                d='M0 0q0 10.68 6.442 16.573 6.442 5.89 18.005 5.892 8.148 0 12.939-2.698 4.79-2.698 4.79-6.993-.001-2.204-1.211-4.625-1.213-2.424-2.863-3.964-2.093 1.1-4.956 1.762-2.863.66-5.396.66-10.021 0-10.021-10.461v-31.384H0Z'
              ></path>
            </g>
            <g id='g50' transform='translate(325.334 214.804)'>
              <path
                id='path52'
                fill='#142248'
                fillOpacity='1'
                fillRule='nonzero'
                stroke='none'
                d='M0 0q.772.88 2.202.881 3.304 0 3.304-3.964v-2.093H2.312q-3.082 0-3.083 2.864Q-.771-.882 0 0m-4.075-7.874q2.424-2.147 6.277-2.147h3.304q-.332-3.414-3.744-3.413-.881-.001-1.707.22-.826.22-1.597.55l-1.431-4.294Q-.77-18.06 2.092-18.06q4.295 0 6.773 2.588t2.478 7.213v5.286Q11.342.99 8.974 3.359 6.606 5.726 2.533 5.726q-3.963 0-6.497-2.257-2.532-2.259-2.533-5.781.001-3.415 2.422-5.562'
              ></path>
            </g>
            <g id='g54' transform='translate(315.313 134.637)'>
              <path
                id='path56'
                fill='#142248'
                fillOpacity='1'
                fillRule='nonzero'
                stroke='none'
                d='M0 0q-3.416.001-5.837-2.368-2.424-2.367-2.423-5.781 0-3.412 2.423-5.891Q-3.416-16.518 0-16.518q3.522 0 5.891 2.478 2.367 2.479 2.368 5.891 0 3.414-2.368 5.781Q3.522.001 0 0'
              ></path>
            </g>
            <g id='g58' transform='translate(341.742 132.27)'>
              <path
                id='path60'
                fill='#142248'
                fillOpacity='1'
                fillRule='nonzero'
                stroke='none'
                d='M0 0q-2.424 2.369-5.947 2.368-3.414.001-5.781-2.368-2.368-2.367-2.368-5.781t2.368-5.892q2.367-2.477 5.781-2.477 3.523 0 5.947 2.477 2.422 2.479 2.423 5.892Q2.422-2.367 0 0'
              ></path>
            </g>
            <g id='g62' transform='translate(102.197 96.486)'>
              <path
                id='path64'
                fill='#192248'
                fillOpacity='1'
                fillRule='nonzero'
                stroke='none'
                d='M0 0v-36.425c0-4.246.742-7.979 2.206-11.094 1.462-3.113 3.492-5.732 6.034-7.788 2.563-2.074 5.668-3.662 9.23-4.722 3.603-1.073 7.534-1.617 11.684-1.617 4.148 0 8.08.544 11.685 1.617 3.561 1.06 6.666 2.649 9.229 4.722 2.544 2.058 4.574 4.678 6.034 7.788 1.464 3.115 2.207 6.848 2.207 11.094V0H41.598v-32.849c0-11.271-6.767-13.636-12.444-13.636-5.676 0-12.443 2.365-12.443 13.636V0Z'
              ></path>
            </g>
            <g id='g66' transform='translate(231.978 96.486)'>
              <path
                id='path68'
                fill='#192248'
                fillOpacity='1'
                fillRule='nonzero'
                stroke='none'
                d='M0 0v-36.425c0-4.246.742-7.979 2.206-11.094 1.462-3.113 3.492-5.732 6.034-7.788 2.563-2.074 5.668-3.662 9.23-4.722 3.603-1.073 7.534-1.617 11.684-1.617 4.148 0 8.08.544 11.685 1.617 3.561 1.06 6.666 2.649 9.229 4.722 2.544 2.058 4.574 4.678 6.034 7.788 1.464 3.115 2.207 6.848 2.207 11.094V0H41.598v-32.849c0-11.271-6.767-13.636-12.444-13.636-5.676 0-12.443 2.365-12.443 13.636V0Z'
              ></path>
            </g>
            <g id='g70' transform='translate(223.774 64.162)'>
              <path
                id='path72'
                fill='#192248'
                fillOpacity='1'
                fillRule='nonzero'
                stroke='none'
                d='M0 0q-1.73 3.337-4.644 5.483-2.926 2.144-6.674 3.278a55 55 0 0 1-7.812 1.729l-10.845 1.55q-3.818.595-5.721 1.429-1.907.833-1.907 2.862 0 2.025 2.085 3.278 2.086 1.25 7.45 1.25a51 51 0 0 0 6.897-.451c.247-.036.484-.078.726-.117 2.335-.406 4.447-.876 6.3-1.423 1.959-.575 3.649-1.188 5.1-1.849 0 0 6.765-3.04 6.768-3.036v15.312a53 53 0 0 1-4.263 1.768q-.687.258-1.436.51c-.145.05-.266.098-.416.148-.036.012-.055.012-.089.023-.145.047-.275.095-.423.143-5.532 1.759-11.326 2.79-17.131 2.981-5.049.165-10.407-.211-15.264-1.67q-5.364-1.611-8.821-4.411-3.458-2.802-5.066-6.556c-1.072-2.502-1.609-5.227-1.609-8.165q-.002-5.246 1.908-8.702a16.06 16.06 0 0 1 5.184-5.661q3.278-2.208 7.569-3.339a69 69 0 0 1 8.941-1.727l6.197-.716q4.41-.597 6.498-1.491 2.085-.891 2.087-3.278c0-1.51-.833-2.561-2.507-3.156q-2.501-.895-7.388-.894c-2.86 0-5.585.218-8.165.656q-3.876.652-6.914 1.549c-2.027.595-3.776 1.229-5.277 1.913 0 0-6.999 3.144-7.002 3.141v-15.842a55 55 0 0 1 4.41-1.83q.71-.266 1.486-.527c.15-.052.276-.101.43-.153.037-.013.057-.012.093-.024.15-.049.284-.099.437-.147q3.932-1.254 9.356-2.208 5.423-.951 12.456-.951c8.975 0 16.013 1.866 21.098 5.602q7.629 5.6 7.629 15.613Q1.731-3.34 0 0'
              ></path>
            </g>
            <g id='g74' transform='translate(319.057 185.554)'>
              <path
                id='path76'
                fill='#142248'
                fillOpacity='1'
                fillRule='nonzero'
                stroke='none'
                d='M0 0v-29.993h-18.941q-4.625 0-6.276 2.257-1.653 2.256-1.652 8.424V0h-17.509v-19.312q0-5.508-1.707-8.094-1.708-2.587-5.341-2.587-6.276 0-6.277 7.818V0h-17.729v-19.312q0-5.508-1.707-8.094-1.707-2.587-5.341-2.587-6.276 0-6.276 7.818V0h-17.73v-19.862q0-5.948-1.651-8.039-1.653-2.093-6.387-2.092h-130.712V0h-17.51v-48.934q0-7.707-7.378-7.708-2.091 0-4.9.55-2.808.552-4.129 1.322l-4.185-13.105q2.42-1.43 6.607-2.423a36.2 36.2 0 0 1 8.369-.991q10.682 0 16.904 6.443t6.222 17.564v1.872h133.905q7.046-.001 12.554 5.506 7.267-6.607 15.967-6.607 5.175 0 9.746 2.037 4.569 2.036 6.662 5.231 4.733-7.269 14.756-7.268 5.285 0 9.746 1.982 4.459 1.981 6.552 5.286 3.742-6.168 13.434-6.167h39.423V0Z'
              ></path>
            </g>
            <g id='g78' transform='translate(73.492 96.486)'>
              <path
                id='path80'
                fill='#192248'
                fillOpacity='1'
                fillRule='nonzero'
                stroke='none'
                d='M0 0v-28.301q0-5.947-2.754-8.809-2.754-2.864-7.709-2.864-5.284.002-7.761 2.313-2.48 2.312-2.481 7.599V0h-17.727v-33.696q0-5.508 1.816-9.525c1.211-2.682 2.898-4.9 5.067-6.663 2.164-1.76 4.695-3.066 7.596-3.909q4.349-1.267 9.417-1.266 4.294 0 8.587 1.487 4.298 1.485 6.39 4.017c0-5.58-1.25-9.578-3.744-12.003q-3.745-3.632-12.223-3.632-2.976 0-5.785.385-2.806.385-5.007.882c-1.471.329-2.698.658-3.691.99-.378.126-.689.234-.965.333V-77c.594-.154 1.217-.31 1.902-.469 2.053-.477 4.442-.898 7.156-1.267q4.075-.548 8.92-.549 6.939 0 12.555 1.704c3.744 1.142 6.975 2.938 9.69 5.396 2.718 2.461 4.807 5.656 6.277 9.581q2.202 5.893 2.201 14.263V0z'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
YusurLogo.defaultProps = {
  height: adminLayoutVariables.headerHeight - 8,
  width: adminLayoutVariables.drawerWidth - 8,
};
