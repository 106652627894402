import { useTranslation } from "@health/i18n";
import { Typography } from "@health/ui";
import React, { FC } from "react";
import { useFormErrorsStyles } from "./styles/useFormErrorsStyles";

interface FormErrorsProps {
  errors: Array<any>;
}

export const FormErrors: FC<FormErrorsProps> = props => {
  const { errors } = props;
  const { classes } = useFormErrorsStyles();
  const { t } = useTranslation();

  return (
    <>
      {errors && (
        <div className={classes.form}>
          {errors.map((error, index) => (
            <Typography
              className={classes.message}
              key={`${error.code}${index}`}
              fontFamily='CoHeadlineTrial-Light'
              variant='subtitle2'
              color='error'
            >
              {t(error.message)}
            </Typography>
          ))}
        </div>
      )}
    </>
  );
};
