import { makeStyles } from "tss-react/mui";

export const useCustomTransferListStyle = makeStyles()(() => ({
  title: {
    fontSize: "16px",
    fontWeight: "bold",
  },

  button: {
    width: "60px",
    height: "60px",
    background: "#ffff",
    borderRadius: "5px",
    border: "none",
    marginBottom: "10px",
  },
}));
export const useCustomListStyle = makeStyles()(() => ({
  title: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  divider: {
    borderColor: "#1C2346",
    borderWidth: "1px",
  },
}));
export const useCustomListComponentStyle = makeStyles()(() => ({
  listItemText: {
    color: "#6C819F",
  },
  listItem: {
    "&.MuiListItem-root": {
      borderRadius: 0,
    },
  },
}));
