import { i18n, useTranslation } from "@health/i18n";
import { OrderStatus, PrescriptionDispenseStatus } from "@health/queries";
import {
  Box,
  Card,
  Divider,
  getTranslatedNameField,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  Typography,
} from "@health/ui";
import moment from "moment";
import DeliveryWorkflow from "pages/PrescriptionsManagement/components/delivery-workflow/delivery-workflow.component";
import { ICustomOrder } from "pages/PrescriptionsManagement/types";
import React, { FC } from "react";
import CardContent from "./CardContent.component";
import { useLifeCycleStyles } from "./LifeCycle.styles";
import { getTypeEvent, isDeliveryStatus, isOrderEventFailedType } from "./utils";

type OrdersEventsProps = {
  order: ICustomOrder;
  dispenseStatus: PrescriptionDispenseStatus;
  deliveryWorkflowId: string | null | undefined;
};

export const OrdersEvents: FC<OrdersEventsProps> = ({ order, dispenseStatus, deliveryWorkflowId }) => {
  const { t } = useTranslation("Admin");
  const { classes, cx } = useLifeCycleStyles({ dispenseStatus });

  const branchName = getTranslatedNameField(order.branch);

  return (
    <Timeline sx={{ margin: 0, padding: "0px 16px" }}>
      {order.events?.map(event => {
        const orderEventFailedType = isOrderEventFailedType(event?.type!);

        return (
          <TimelineItem key={event?.id}>
            <TimelineOppositeContent className={classes.content}>
              <Typography className={classes.dateBold}> {moment(event?.date).locale(i18n.language).format("D MMM YYYY")} </Typography>
            </TimelineOppositeContent>

            <TimelineSeparator sx={{ position: "relative" }}>
              <TimelineDot variant='outlined' className={classes.dot} />
              <TimelineConnector className={classes.separator} />
            </TimelineSeparator>

            <TimelineContent className={classes.position}>
              <Card className={cx(classes.card, event?.isParentEvent && classes.parentCard)}>
                <div className={classes.flexColumn}>
                  <div className={classes.flex}>
                    <Typography className={classes.textBold}> {getTypeEvent(event?.type)} </Typography>
                    <Typography className={classes.date}> {moment(event?.date).locale(i18n.language).format("h:mm:ss a")} </Typography>
                  </div>

                  {isDeliveryStatus(event?.type!) && deliveryWorkflowId && (
                    <div className={classes.deliveryWorkflowWrapper}>
                      <DeliveryWorkflow deliveryWorkflowId={deliveryWorkflowId} />
                    </div>
                  )}
                </div>

                <Divider className={classes.line} />

                <Box display='flex' justifyContent='space-between'>
                  <Box>
                    <CardContent title={t("Order Number")} subTitle={`#${event?.orderNumber}`} />
                  </Box>
                  <Box>
                    <CardContent title={t("Pharmacy")} subTitle={event.branchName ? event.branchName : branchName} />
                  </Box>
                </Box>

                {orderEventFailedType && order.status === OrderStatus.Failed && event?.isParentEvent && (
                  <Box sx={{ mt: 2 }}>
                    <CardContent title={t("Fail Reason")} subTitle={order.failReason} />
                  </Box>
                )}

                {order.helpDiskTicketId && (
                  <Box>
                    <CardContent title={t("Help Disk Ticket Id")} subTitle={order.helpDiskTicketId} />
                  </Box>
                )}
              </Card>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};
