import { combineErrors, getRequiredValidation, mobileStartRegex, patternMobile, required } from "@health/common";
import { useTranslation } from "@health/i18n";
import { AppTypes } from "@health/queries";
import { Box, Button, TextField, Typography } from "@health/ui";
import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { useAccountReSendOtpCodeHook } from "../AccountReSendOtpCode.hook";
import { FormProps } from "../dialog/types";
import { dialogStep } from "../dialog/utils";
import { FormErrors } from "../FormErrors.component";
import { useLoginFormHooks } from "../LoginForm.hook";
import { useStyles } from "../styles/useStyles";

export const LoginForm: FC<FormProps> = ({ app, onLogin, onChangeStep, onForgetPassword, onRouteView, hasForget = false }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const {
    handleSubmit,
    formState: { errors: formErrorsData },
    register,
    watch,
    setError,
  } = useForm({
    mode: "all",
  });

  const mobile = watch("username");

  const { errors, onSubmit } = useLoginFormHooks(app, onLogin, onChangeStep, onRouteView);
  const formErrors = combineErrors(formErrorsData, errors);
  const errorLength = Boolean(errors?.length);

  const { handleResendCode } = useAccountReSendOtpCodeHook(mobile, onChangeStep, true);

  const handleForgetPassword = () => {
    onForgetPassword ? onForgetPassword() : onChangeStep(dialogStep.forget);
  };
  const handleVerify = () => {
    if (!isMobileNumberUsed) {
      setError("username", {
        message: t("You must use your mobile number to verify your account"),
      });
      return;
    }
    handleResendCode();
  };

  const isAccountDisabled = errors?.some(item => item.message === "Account disabled");
  const isMobileNumberUsed = mobileStartRegex.test(mobile);
  const pattern = isMobileNumberUsed
    ? patternMobile
    : {
        value: /(\d{10})/,
        message: t("National ID must be 10 digits"),
      };

  return (
    <div className={classes.content}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box m='5px 0px'>
          <TextField
            fullWidth
            name='username'
            label={t("Mobile No. / National ID")}
            placeholder={t("Mobile No. / National ID")}
            inputProps={{ className: "mobile-phone-rtl-fix" }}
            error={Boolean(formErrors?.username?.message)}
            helperText={
              <div
                dangerouslySetInnerHTML={{
                  __html: t(formErrors?.username?.message, {
                    phone: "<span class='mobile-phone-rtl-fix'>+966999999999</span>",
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            }
            {...register("username", {
              required: required(t),
              pattern: app === AppTypes.Customer ? pattern : null,
            })}
          />
        </Box>
        <Box m='15px 0px 10px'>
          <TextField
            fullWidth
            name='password'
            type='password'
            label={t("Password")}
            placeholder={t("Password")}
            error={Boolean(formErrors?.password?.message)}
            helperText={t(formErrors.password?.message)}
            {...register("password", {
              required: getRequiredValidation(t, true),
            })}
          />
        </Box>
        <Box display='flex' justifyContent={errorLength ? "space-between" : "flex-end"} alignItems='baseline'>
          {errorLength &&
            (!isAccountDisabled ? (
              <FormErrors errors={errors} />
            ) : (
              <Typography m={1} fontFamily='CoHeadlineTrial-Light' variant='subtitle2' color='error' data-testid='accountIsDisabledMessage'>
                {t("Account is disabled")}
              </Typography>
            ))}

          {hasForget &&
            (isAccountDisabled ? (
              <Button variant='text' className={classes.forgot} onClick={handleVerify} data-testid='verifyYourAccount'>
                {t("Verify your account")}
              </Button>
            ) : (
              <Button variant='text' className={classes.forgot} onClick={handleForgetPassword} data-testid='forogtPasswordButton'>
                {t("Forgot password?")}
              </Button>
            ))}
        </Box>
        <Button fullWidth variant='contained' type='submit' size='large' className={classes.button} data-testid='submitLoginButton'>
          {t("Sign in")}
        </Button>
      </form>
    </div>
  );
};
