import { useTranslation } from "@health/i18n";
import { Box, Button, Typography, useIsMobileView, YusurLogo } from "@health/ui";
import moment from "moment";
import React, { FC } from "react";
import { useEntryContainerStyles } from "./styles/useEntryContainerStyles";

type EntryContainerProps = {
  title: string;
  buttonLabel?: string;
  onClick?: () => void;
};

export const EntryContainer: FC<EntryContainerProps> = props => {
  const { children, onClick: handleClick, title, buttonLabel } = props;
  const { classes } = useEntryContainerStyles();
  const year = moment().year();
  const { t } = useTranslation();
  const isMobile = useIsMobileView();
  return (
    <Box mt='100px' height='600px'>
      <div className={classes.content}>
        <div className={classes.form}>
          <YusurLogo height={isMobile ? 96 : 61} width={isMobile ? 147 : 109} />
          {title && (
            <Typography className={classes.singIn} mt='20px'>
              {title}
            </Typography>
          )}

          {children}
        </div>
        {buttonLabel && (
          <Button variant='text' onClick={handleClick}>
            {buttonLabel}
          </Button>
        )}
        {isMobile ? (
          <></>
        ) : (
          <div className={classes.footer}>
            <YusurLogo width={66} height={50} />
            <Typography
              sx={{
                marginInline: 1,
              }}
              className={classes.footerText}
            >
              {t("Copyright")} © {year} {t("NUPCO. All rights reserved")}.
            </Typography>
          </div>
        )}
      </div>
    </Box>
  );
};

EntryContainer.defaultProps = {
  buttonLabel: undefined,
  onClick: () => {},
};
