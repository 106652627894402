/* eslint-disable max-lines */
/* eslint-disable react/default-props-match-prop-types */
import { useTranslation } from "@health/i18n";
import { Box, Checkbox, CircularProgress, darkRed, FormControlLabel, Grid, paths, TextField, Typography } from "@health/ui";
import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import React, { FC } from "react";
import GoogleMapSearchComponent from "../GoogleMapSearch/GoogleMapSearchComponent";
import { useLocationPickerHooks } from "./LocationPicker.hook";
import { useLocationPickerStyles } from "./styles";
import { ILocationPickerProps } from "./types";

const LIBRARIES: any = ["places"];

const LocationPicker: FC<ILocationPickerProps> = props => {
  const { classes } = useLocationPickerStyles();
  const { i18n } = useTranslation();

  const { isLoaded } = useLoadScript({
    id: "script-loader",
    version: "quarterly",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY!,
    libraries: LIBRARIES,
    language: i18n.language,
  });

  const {
    containerStyle,
    mapContainerStyle,
    searchContainerStyle,
    searchTitle,
    location,
    locations,
    addressError,
    addressRef,
    hasSearch,
    addressName,
    icon,
    hasLocationLngLat,
    center,
    defaultZoom,
    defaultSearchValue,
    isChecked,
    containerClassName,
    t,
    handlePickLocation,
    handleIsChecked,
    handleLocationChanged,
    handleSearchLocation,
  } = useLocationPickerHooks(props);
  if (!isLoaded)
    return (
      <Box sx={{ ...mapContainerStyle, display: "flex", alignContent: "center", justifyContent: "center" }}>
        <Typography>
          <CircularProgress size={20} sx={{ mx: 2 }} />
          {t("Loading map")}
        </Typography>
      </Box>
    );

  return (
    <Grid container item sx={{ ...containerStyle }}>
      <Grid item xs={12} mb='15px' sx={{ ...searchContainerStyle }}>
        {searchTitle && (
          <Typography fontFamily='CoHeadlineTrial-Light, Arab Kufi Medium' fontSize='16px'>
            {searchTitle}
          </Typography>
        )}
        {hasSearch && <GoogleMapSearchComponent defaultValue={defaultSearchValue} onLocationChange={handleSearchLocation} />}
      </Grid>
      <Grid ref={addressRef} item xs={12} sx={{ ...mapContainerStyle }}>
        {addressError && (
          <Typography color={"red"} my='5px'>
            {addressError}
          </Typography>
        )}
        {hasLocationLngLat && (
          <Box m='20px 0'>
            <FormControlLabel
              control={<Checkbox checked={isChecked} onChange={handleIsChecked} />}
              label={t("Show Longitude and Latitude Inputs")}
            />
            {isChecked && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    name='latitude'
                    value={location?.lat}
                    onChange={handleLocationChanged("lat")}
                    placeholder={t("Latitude")}
                    label={t("Latitude")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size='small'
                    fullWidth
                    name='longitude'
                    value={location?.lng}
                    onChange={handleLocationChanged("lng")}
                    placeholder={t("Longitude")}
                    label={t("Longitude")}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        )}
        <GoogleMap
          mapContainerClassName={containerClassName || classes.containerMapStyle}
          center={center}
          zoom={defaultZoom}
          onClick={handlePickLocation}
        >
          <Marker
            clickable
            position={location}
            icon={{
              path: paths?.[icon],
              fillColor: darkRed,
              fillOpacity: 1,
              strokeWeight: 0,
              rotation: 0,
              scale: 2,
              anchor: new google.maps.Point(15, 15),
            }}
          >
            {addressName && (
              <InfoWindow position={location}>
                <Typography>{addressName}</Typography>
              </InfoWindow>
            )}
          </Marker>

          {!!locations?.length &&
            locations?.map(locationItem => (
              <Marker
                key={locationItem?.id}
                clickable
                position={locationItem?.coordinates}
                icon={{
                  path: paths?.[icon] | (paths?.["markerMap"] as any),
                  fillColor: darkRed,
                  fillOpacity: 1,
                  strokeWeight: 0,
                  rotation: 0,
                  scale: 2,
                  anchor: new google.maps.Point(15, 15),
                }}
              ></Marker>
            ))}
        </GoogleMap>
      </Grid>
    </Grid>
  );
};

LocationPicker.defaultProps = {
  icon: "markerMap",
  hasSearch: false,
  hasLocationLngLat: false,
  defaultCenter: { lat: 24.750643, lng: 46.707766 },
  location: undefined,
  containerClassName: undefined,
  defaultZoom: 13,
};

export default LocationPicker;
