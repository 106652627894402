/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PrescriptionOrderBulkFileRecordsQueryVariables = Types.Exact<{
  filters: Types.PrescriptionOrderRecordsFilterInput;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type PrescriptionOrderBulkFileRecordsQuery = { __typename?: 'Query', prescriptionOrderBulkFileRecords?: { __typename?: 'PrescriptionOrderBulkFileRecordConnection', totalCount?: number | null, edges?: Array<{ __typename?: 'PrescriptionOrderBulkFileRecordEdge', cursor?: string | null, node?: { __typename?: 'PrescriptionOrderBulkFileRecord', id?: string | null, processingStatus?: Types.BulkProcessingStatus | null, updatedDate?: any | null, failureReason?: string | null, createdDate?: any | null, columns?: Array<{ __typename?: 'BulkFileRecordColumn', code?: string | null, fieldType?: string | null, valueBoolean?: boolean | null, valueDate?: any | null, valueFloat?: number | null, valueInteger?: number | null, valueString?: string | null } | null> | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null } | null } | null };


export const PrescriptionOrderBulkFileRecordsDocument = gql`
    query PrescriptionOrderBulkFileRecords($filters: PrescriptionOrderRecordsFilterInput!, $first: Int, $after: String, $before: String) {
  prescriptionOrderBulkFileRecords(
    filters: $filters
    first: $first
    after: $after
    before: $before
  ) {
    totalCount
    edges {
      cursor
      node {
        id
        processingStatus
        updatedDate
        failureReason
        createdDate
        columns {
          code
          fieldType
          valueBoolean
          valueDate
          valueFloat
          valueInteger
          valueString
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __usePrescriptionOrderBulkFileRecordsQuery__
 *
 * To run a query within a React component, call `usePrescriptionOrderBulkFileRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrescriptionOrderBulkFileRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrescriptionOrderBulkFileRecordsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function usePrescriptionOrderBulkFileRecordsQuery(baseOptions: Apollo.QueryHookOptions<PrescriptionOrderBulkFileRecordsQuery, PrescriptionOrderBulkFileRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PrescriptionOrderBulkFileRecordsQuery, PrescriptionOrderBulkFileRecordsQueryVariables>(PrescriptionOrderBulkFileRecordsDocument, options);
      }
export function usePrescriptionOrderBulkFileRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrescriptionOrderBulkFileRecordsQuery, PrescriptionOrderBulkFileRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PrescriptionOrderBulkFileRecordsQuery, PrescriptionOrderBulkFileRecordsQueryVariables>(PrescriptionOrderBulkFileRecordsDocument, options);
        }
export type PrescriptionOrderBulkFileRecordsQueryHookResult = ReturnType<typeof usePrescriptionOrderBulkFileRecordsQuery>;
export type PrescriptionOrderBulkFileRecordsLazyQueryHookResult = ReturnType<typeof usePrescriptionOrderBulkFileRecordsLazyQuery>;
export type PrescriptionOrderBulkFileRecordsQueryResult = Apollo.QueryResult<PrescriptionOrderBulkFileRecordsQuery, PrescriptionOrderBulkFileRecordsQueryVariables>;