import { useTranslation } from "@health/i18n";
import { useOrderLifeCycleAdminQuery } from "@health/queries";
import { getTranslatedNameField } from "@health/ui";
import { ICustomOrder } from "pages/PrescriptionsManagement/types";
import { useState } from "react";
import { OrdersEventsType } from "./OrdersEvents.types";

export const useLifeCycleHooks = ({ orderId }: { orderId: string }) => {
  const { t, i18n } = useTranslation("Admin");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const { data, loading } = useOrderLifeCycleAdminQuery({
    variables: { id: orderId },
    skip: !open,
  });

  const deliveryWorkflowId = data?.order?.shipmentInformation?.data?.deliveryWorkflowId;

  const order = {
    ...data?.order,
    events: [
      ...(data?.order?.events?.map(event => ({
        ...event,
        isParentEvent: true,
      })) || []),
      ...(data?.order?.children?.reduce((acc, child) => {
        child?.events?.forEach(
          event =>
            event &&
            acc.push({ ...event, number: child?.number, branchName: getTranslatedNameField(child?.branch)! || "", isParentEvent: false })
        );
        return acc;
      }, [] as OrdersEventsType) || []),
    ],
  } as ICustomOrder;

  return {
    open,
    loading,
    order,
    deliveryWorkflowId,
    t,
    i18n,
    handleClickOpen,
    handleClickClose,
  };
};
