/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderLifeCycleAdminQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type OrderLifeCycleAdminQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: string, status: Types.OrderStatus, number?: string | null, failReason?: string | null, helpDiskTicketId?: string | null, branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null } | null, events?: Array<{ __typename?: 'OrderEvent', id: string, date?: any | null, type?: Types.OrderEventsEnum | null, orderNumber?: string | null } | null> | null, children?: Array<{ __typename?: 'Order', id: string, number?: string | null, status: Types.OrderStatus, type?: Types.OrderTypesEnum | null, branch?: { __typename?: 'Branch', name: string, nameAr?: string | null } | null, events?: Array<{ __typename?: 'OrderEvent', id: string, date?: any | null, type?: Types.OrderEventsEnum | null, orderNumber?: string | null } | null> | null } | null> | null, shipmentInformation?: { __typename?: 'DeliveryShipmentGetShipmentResponseResult', data?: { __typename?: 'DeliveryShipmentGetShipmentResponse', deliveryWorkflowId?: string | null } | null } | null } | null };


export const OrderLifeCycleAdminDocument = gql`
    query orderLifeCycleAdmin($id: ID!) {
  order(id: $id) {
    id
    status
    number
    failReason
    helpDiskTicketId
    branch {
      id
      name
      nameAr
    }
    events {
      id
      date
      type
      orderNumber
    }
    children {
      id
      branch {
        name
        nameAr
      }
      number
      status
      type
      events {
        id
        date
        type
        orderNumber
      }
    }
    shipmentInformation {
      data {
        deliveryWorkflowId
      }
    }
  }
}
    `;

/**
 * __useOrderLifeCycleAdminQuery__
 *
 * To run a query within a React component, call `useOrderLifeCycleAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderLifeCycleAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderLifeCycleAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderLifeCycleAdminQuery(baseOptions: Apollo.QueryHookOptions<OrderLifeCycleAdminQuery, OrderLifeCycleAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderLifeCycleAdminQuery, OrderLifeCycleAdminQueryVariables>(OrderLifeCycleAdminDocument, options);
      }
export function useOrderLifeCycleAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderLifeCycleAdminQuery, OrderLifeCycleAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderLifeCycleAdminQuery, OrderLifeCycleAdminQueryVariables>(OrderLifeCycleAdminDocument, options);
        }
export type OrderLifeCycleAdminQueryHookResult = ReturnType<typeof useOrderLifeCycleAdminQuery>;
export type OrderLifeCycleAdminLazyQueryHookResult = ReturnType<typeof useOrderLifeCycleAdminLazyQuery>;
export type OrderLifeCycleAdminQueryResult = Apollo.QueryResult<OrderLifeCycleAdminQuery, OrderLifeCycleAdminQueryVariables>;