import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { FC } from "react";

export const DeliveringIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon xmlns='http://www.w3.org/2000/svg' width='58.42' height='43.23' viewBox='0 0 58.42 43.23' {...props}>
      <path
        id='icons8-delivery'
        d='M3.272,8A3.29,3.29,0,0,0,0,11.272V41.883a3.524,3.524,0,0,0,3.505,3.505H8.288a7,7,0,0,0,13.8,0H39.835a7,7,0,0,0,13.8,0h1.278a3.675,3.675,0,0,0,3.505-3.505V30.67a7.926,7.926,0,0,0-.936-3.428,13.706,13.706,0,0,0-.908-1.57l-.014-.014L51.752,19.2l-.014-.014c-1.036-1.3-2.7-3.012-5-3.012H37.389a3.089,3.089,0,0,0-1.168.242v-5.03A3.393,3.393,0,0,0,32.829,8Zm0,2.337H32.829a1.239,1.239,0,0,1,1.054,1.054V43.052H22.09a7,7,0,0,0-13.8,0H3.505a1.165,1.165,0,0,1-1.168-1.168V11.272A.9.9,0,0,1,3.272,10.337Zm14.253,2.337A11.684,11.684,0,1,0,29.21,24.357,11.718,11.718,0,0,0,17.526,12.674Zm0,2.337a9.347,9.347,0,1,1-9.347,9.347A9.377,9.377,0,0,1,17.526,15.01Zm-1.168,2.337v6.426l-4.2,3.154,1.4,1.871,5.144-3.857V17.347Zm21.031,1.168h4.674V29.031a3.675,3.675,0,0,0,3.505,3.505H56.083v9.347a1.368,1.368,0,0,1-1.168,1.168H53.636a7,7,0,0,0-13.8,0H36.22V19.684a1.368,1.368,0,0,1,1.168-1.168Zm7.01,0h2.337c.968,0,2.341,1.091,3.177,2.131l4.747,6.367a10.644,10.644,0,0,1,.726,1.264,6.829,6.829,0,0,1,.561,1.921H45.567A1.368,1.368,0,0,1,44.4,29.031ZM15.189,39.547a4.674,4.674,0,1,1-4.674,4.674A4.653,4.653,0,0,1,15.189,39.547Zm31.547,0a4.674,4.674,0,1,1-4.674,4.674A4.653,4.653,0,0,1,46.736,39.547Z'
        transform='translate(0 -8)'
        fill='#1C2346'
      />
    </SvgIcon>
  );
};
