import { useTranslation } from "@health/i18n";
import {
  AppTypes,
  BulkFile,
  BulkFilesOrderDirection,
  BulkFileSortField,
  BulkProcessingStatus,
  BulkTemplateCode,
  QueryBulkFilesArgs,
  useBulkFilesQuery,
  useDataGridState,
  UsersQueryVariables,
  UserStatus,
  useUsersQuery,
} from "@health/queries";
import { mapActiveFiltersForHeader, useAutocompleteCustomHook } from "@health/ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "shared/constants";
import { getFileListColumns } from "./FileListColumns.component";
import { useFileListFilter } from "./FileListFilter.component";

export default function useFileListScreenHook() {
  const { t, i18n } = useTranslation("Admin");
  const navigate = useNavigate();

  const inputs: QueryBulkFilesArgs = {
    first: 10,
    after: null,
    before: null,
    last: null,
    filters: { code: BulkTemplateCode.PrescriptionOrder },
    sortBy: { direction: BulkFilesOrderDirection.Desc, field: BulkFileSortField.CreatedDate },
  };

  const { data, isLoading, pageSize, doChangePageSize, doSort, doGoToNext, doGoToPrevious, setVariables } = useDataGridState({
    useCustomQuery: useBulkFilesQuery,
    input: inputs,
  });

  const bulkFiles = data?.bulkFiles?.edges?.map(item => item?.node) as BulkFile[];
  const pageInfo = data?.bulkFiles?.pageInfo;

  const inputsUsers: UsersQueryVariables = {
    first: 10,
    after: null,
    before: null,
    last: null,
    filter: {
      search: "a",
      appType: AppTypes.Admin,
      status: UserStatus.Active,
    },
  };

  const dataAccessorForUsers = "users";
  const {
    data: users,
    isLoading: loadingUsers,
    onFetchMoreData: onFetchMoreUsers,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useUsersQuery,
    input: inputsUsers,
    dataAccessor: dataAccessorForUsers,
  });
  const pageInfoForBranches = users?.[dataAccessorForUsers]?.pageInfo;
  const hasMoreForInfoForBranches = pageInfoForBranches?.hasNextPage;
  const pharmacyBranches = users?.[dataAccessorForUsers]?.edges?.map(item => item?.node)!;

  const usersData = {
    loading: loadingUsers,
    onFetchMore: onFetchMoreUsers,
    hasMore: hasMoreForInfoForBranches,
    options: pharmacyBranches,
  };

  const [activeFilters, setActiveFilters] = useState<Record<string, any>>({});

  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const handleToggleFilters = () => {
    setIsFilterOpened(state => !state);
  };

  const handleApplyFilters = filteredData => {
    const { createdBy, created, createdTo, updated, updatedTo } = filteredData;
    setActiveFilters({
      ...filteredData,
      createdBy: createdBy ? { id: createdBy.id, firstName: createdBy.firstName, lastName: createdBy.lastName } : null,
    });
    setVariables(state => ({
      ...state,
      first: pageSize,
      after: null,
      last: null,
      before: null,
      filters: {
        createdBy: createdBy?.id ?? null,
        processingStatus: BulkProcessingStatus[filteredData?.processingStatus],
        createdDate:
          created || createdTo
            ? {
                lte: createdTo || null,
                gte: created || null,
              }
            : null,
        updatedDate:
          updated || updatedTo
            ? {
                lte: updatedTo || null,
                gte: updated || null,
              }
            : null,
        code: BulkTemplateCode.PrescriptionOrder,
      },
    }));
  };

  const pageHeaderFiltersMappers = {
    createdBy: value => `${value.firstName ?? ""} ${value?.lastName ?? ""}`,
  };
  const activeFiltersDisplay = mapActiveFiltersForHeader(activeFilters, pageHeaderFiltersMappers);

  const { filters } = useFileListFilter(activeFilters, usersData);

  const handleSortData = row => {
    doSort(row.sortColumnEnum);
  };

  const handleGotoNext = () => {
    doGoToNext();
  };
  const handleGoToPrevious = () => {
    doGoToPrevious();
  };

  const handlePageSizeChange = (size: number): void => {
    doChangePageSize(size);
  };

  const handleDetailClick = row => {
    return navigate(ROUTE_PATHS.prescriptions.fileList.details.fullPath.replace(":id", row.id));
  };

  return {
    columns: getFileListColumns({ t, currentLang: i18n.language, handleDetailClick }),
    pageSize,
    pageInfo,
    isLoading,
    bulkFiles,
    activeFiltersDisplay,
    isFilterOpened,
    activeFilters,
    filters,
    handleApplyFilters,
    handleToggleFilters,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
    handleSortData,
  };
}
