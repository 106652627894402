/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBulkMedicalDeliveryRequestShipmentInfotmationQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.MedicalOrderDeliveryFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetBulkMedicalDeliveryRequestShipmentInfotmationQuery = { __typename?: 'Query', medicalDeliveryRequests?: { __typename?: 'MedicalDeliveryRequestCountableConnection', edges: Array<{ __typename?: 'MedicalDeliveryRequestCountableEdge', node: { __typename?: 'MedicalDeliveryRequest', shipmentInformation?: { __typename?: 'DeliveryShipmentGetShipmentResponseResult', code?: number | null, errors?: Array<string | null> | null, data?: { __typename?: 'DeliveryShipmentGetShipmentResponse', awb?: string | null, dispenseOrderIdAccumulated?: string | null, dispenseOrderIdSeparator?: string | null } | null } | null } }> } | null };


export const GetBulkMedicalDeliveryRequestShipmentInfotmationDocument = gql`
    query getBulkMedicalDeliveryRequestShipmentInfotmation($filter: MedicalOrderDeliveryFilterInput, $first: Int) {
  medicalDeliveryRequests(filter: $filter, first: $first) {
    edges {
      node {
        shipmentInformation {
          data {
            awb
            dispenseOrderIdAccumulated
            dispenseOrderIdSeparator
          }
          code
          errors
        }
      }
    }
  }
}
    `;

/**
 * __useGetBulkMedicalDeliveryRequestShipmentInfotmationQuery__
 *
 * To run a query within a React component, call `useGetBulkMedicalDeliveryRequestShipmentInfotmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulkMedicalDeliveryRequestShipmentInfotmationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulkMedicalDeliveryRequestShipmentInfotmationQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetBulkMedicalDeliveryRequestShipmentInfotmationQuery(baseOptions?: Apollo.QueryHookOptions<GetBulkMedicalDeliveryRequestShipmentInfotmationQuery, GetBulkMedicalDeliveryRequestShipmentInfotmationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBulkMedicalDeliveryRequestShipmentInfotmationQuery, GetBulkMedicalDeliveryRequestShipmentInfotmationQueryVariables>(GetBulkMedicalDeliveryRequestShipmentInfotmationDocument, options);
      }
export function useGetBulkMedicalDeliveryRequestShipmentInfotmationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBulkMedicalDeliveryRequestShipmentInfotmationQuery, GetBulkMedicalDeliveryRequestShipmentInfotmationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBulkMedicalDeliveryRequestShipmentInfotmationQuery, GetBulkMedicalDeliveryRequestShipmentInfotmationQueryVariables>(GetBulkMedicalDeliveryRequestShipmentInfotmationDocument, options);
        }
export type GetBulkMedicalDeliveryRequestShipmentInfotmationQueryHookResult = ReturnType<typeof useGetBulkMedicalDeliveryRequestShipmentInfotmationQuery>;
export type GetBulkMedicalDeliveryRequestShipmentInfotmationLazyQueryHookResult = ReturnType<typeof useGetBulkMedicalDeliveryRequestShipmentInfotmationLazyQuery>;
export type GetBulkMedicalDeliveryRequestShipmentInfotmationQueryResult = Apollo.QueryResult<GetBulkMedicalDeliveryRequestShipmentInfotmationQuery, GetBulkMedicalDeliveryRequestShipmentInfotmationQueryVariables>;