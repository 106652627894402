/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../fragment/PageInfo/__generated__/pageInfoFragment';
import { LanguageDisplayFragmentDoc } from '../../../../fragment/languageDisplay/__generated__/languageDisplay';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalDeliveryRequestsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  sortBy?: Types.InputMaybe<Types.MedicalDeliverySortingInput>;
  filter?: Types.InputMaybe<Types.MedicalOrderDeliveryFilterInput>;
}>;


export type MedicalDeliveryRequestsQuery = { __typename?: 'Query', medicalDeliveryRequests?: { __typename?: 'MedicalDeliveryRequestCountableConnection', pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'MedicalDeliveryRequestCountableEdge', node: { __typename?: 'MedicalDeliveryRequest', id: string, created: any, number?: string | null, description?: string | null, carrierName?: string | null, isSupportTicket: boolean, temperature?: string | null, requiredDeliveryTime?: number | null, dropOffContactName?: string | null, dropOffPhoneNumber?: string | null, deliveryType: Types.MedicalDeliveryRequestDeliveryType, deliveryStatus: Types.MedicalDeliveryRequestDeliveryStatus, cost?: { __typename?: 'Money', amount: number, currency: string } | null, shippingPrice?: { __typename?: 'TaxedMoney', net: { __typename?: 'Money', amount: number, currency: string } } | null, weight?: { __typename?: 'Weight', unit: string, value: number } | null, dropOffAddress: { __typename?: 'OrderAddress', id: string, name?: string | null, area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingNumber?: string | null, city?: { __typename?: 'City', id: string, name: string, nameAr?: string | null } | null, translations?: Array<{ __typename?: 'OrderAddressTranslation', name?: string | null, streetAddress1: string, streetAddress2: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null, coordinates?: { __typename?: 'LocationType', lat?: number | null, lng?: number | null } | null }, branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null, vendor: { __typename?: 'Vendor', name: string, nameAr?: string | null } } | null } }> } | null };


export const MedicalDeliveryRequestsDocument = gql`
    query medicalDeliveryRequests($first: Int, $after: String, $last: Int, $before: String, $sortBy: MedicalDeliverySortingInput, $filter: MedicalOrderDeliveryFilterInput) {
  medicalDeliveryRequests(
    first: $first
    after: $after
    last: $last
    before: $before
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      ...PageInfoFragment
    }
    edges {
      node {
        id
        created
        number
        description
        carrierName
        isSupportTicket
        cost {
          amount
          currency
        }
        shippingPrice {
          net {
            amount
            currency
          }
        }
        weight {
          unit
          value
        }
        temperature
        requiredDeliveryTime
        dropOffContactName
        dropOffPhoneNumber
        dropOffAddress {
          id
          name
          area
          streetAddress1
          streetAddress2
          buildingNumber
          city {
            id
            name
            nameAr
          }
          translations {
            name
            streetAddress1
            streetAddress2
            language {
              ...languageDisplay
            }
          }
          coordinates {
            lat
            lng
          }
        }
        deliveryType
        deliveryStatus
        branch {
          id
          name
          nameAr
          vendor {
            name
            nameAr
          }
        }
      }
    }
  }
}
    ${PageInfoFragmentFragmentDoc}
${LanguageDisplayFragmentDoc}`;

/**
 * __useMedicalDeliveryRequestsQuery__
 *
 * To run a query within a React component, call `useMedicalDeliveryRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalDeliveryRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalDeliveryRequestsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMedicalDeliveryRequestsQuery(baseOptions?: Apollo.QueryHookOptions<MedicalDeliveryRequestsQuery, MedicalDeliveryRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalDeliveryRequestsQuery, MedicalDeliveryRequestsQueryVariables>(MedicalDeliveryRequestsDocument, options);
      }
export function useMedicalDeliveryRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalDeliveryRequestsQuery, MedicalDeliveryRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalDeliveryRequestsQuery, MedicalDeliveryRequestsQueryVariables>(MedicalDeliveryRequestsDocument, options);
        }
export type MedicalDeliveryRequestsQueryHookResult = ReturnType<typeof useMedicalDeliveryRequestsQuery>;
export type MedicalDeliveryRequestsLazyQueryHookResult = ReturnType<typeof useMedicalDeliveryRequestsLazyQuery>;
export type MedicalDeliveryRequestsQueryResult = Apollo.QueryResult<MedicalDeliveryRequestsQuery, MedicalDeliveryRequestsQueryVariables>;