/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMedicalDeliveryRequestShipmentInformationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type GetMedicalDeliveryRequestShipmentInformationQuery = { __typename?: 'Query', medicalDeliveryRequest?: { __typename?: 'MedicalDeliveryRequest', shipmentInformation?: { __typename?: 'DeliveryShipmentGetShipmentResponseResult', code?: number | null, errors?: Array<string | null> | null, data?: { __typename?: 'DeliveryShipmentGetShipmentResponse', dispenseOrderIdAccumulated?: string | null, dispenseOrderIdSeparator?: string | null, awb?: string | null } | null } | null } | null };


export const GetMedicalDeliveryRequestShipmentInformationDocument = gql`
    query getMedicalDeliveryRequestShipmentInformation($id: ID!) {
  medicalDeliveryRequest(id: $id) {
    shipmentInformation {
      data {
        dispenseOrderIdAccumulated
        dispenseOrderIdSeparator
        awb
      }
      code
      errors
    }
  }
}
    `;

/**
 * __useGetMedicalDeliveryRequestShipmentInformationQuery__
 *
 * To run a query within a React component, call `useGetMedicalDeliveryRequestShipmentInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMedicalDeliveryRequestShipmentInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMedicalDeliveryRequestShipmentInformationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMedicalDeliveryRequestShipmentInformationQuery(baseOptions: Apollo.QueryHookOptions<GetMedicalDeliveryRequestShipmentInformationQuery, GetMedicalDeliveryRequestShipmentInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMedicalDeliveryRequestShipmentInformationQuery, GetMedicalDeliveryRequestShipmentInformationQueryVariables>(GetMedicalDeliveryRequestShipmentInformationDocument, options);
      }
export function useGetMedicalDeliveryRequestShipmentInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMedicalDeliveryRequestShipmentInformationQuery, GetMedicalDeliveryRequestShipmentInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMedicalDeliveryRequestShipmentInformationQuery, GetMedicalDeliveryRequestShipmentInformationQueryVariables>(GetMedicalDeliveryRequestShipmentInformationDocument, options);
        }
export type GetMedicalDeliveryRequestShipmentInformationQueryHookResult = ReturnType<typeof useGetMedicalDeliveryRequestShipmentInformationQuery>;
export type GetMedicalDeliveryRequestShipmentInformationLazyQueryHookResult = ReturnType<typeof useGetMedicalDeliveryRequestShipmentInformationLazyQuery>;
export type GetMedicalDeliveryRequestShipmentInformationQueryResult = Apollo.QueryResult<GetMedicalDeliveryRequestShipmentInformationQuery, GetMedicalDeliveryRequestShipmentInformationQueryVariables>;