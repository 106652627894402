export const toBase64 = (data: string): string => {
  if (!data) return "";
  try {
    return btoa(data);
  } catch (error) {
    return data;
  }
};

export const fromBase64 = (base64Txt: string): string => {
  try {
    return atob(base64Txt);
  } catch (error) {
    return base64Txt;
  }
};
