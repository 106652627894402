export const prescriptionRoutes = {
  name: "Prescriptions",
  path: "prescriptions",
  list: {
    name: "Prescriptions List",
    path: "list",
    fullPath: "/prescriptions/list",
  },
  fileList: {
    name: "Bulk File List",
    path: "file-list",
    fullPath: "/prescriptions/file-list",
    details: {
      name: "Bulk File Details",
      path: "prescriptions/file-list/details/:id",
      fullPath: "/prescriptions/file-list/details/:id",
    },
  },
};
