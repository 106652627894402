import { ActiveFilters, CustomTable, PageWrapper, StaticFilterDialog } from "@health/ui";
import { useBulkFileDetailsHook } from "./BulkFileDetails.hook";

export function FileListDetailsScreen() {
  const {
    columns,
    pageSize,
    pageInfo,
    isLoading,
    bulkFiles,
    handleGotoNext,
    handleGoToPrevious,
    handlePageSizeChange,
    activeFiltersDisplay,
    isFilterOpened,
    activeFilters,
    filters,
    handleApplyFilters,
    handleToggleFilters,
    handleSortData,
  } = useBulkFileDetailsHook();
  return (
    <PageWrapper
      start={<ActiveFilters onOpenFilters={handleToggleFilters} activeFilters={activeFiltersDisplay} i18nNs='Admin' />}
      actions={
        <>
          <StaticFilterDialog
            isOpen={isFilterOpened}
            onToggleDialog={handleToggleFilters}
            activeFilters={activeFilters}
            filterFields={filters}
            onApplyFilters={handleApplyFilters}
          />
        </>
      }
    >
      <CustomTable
        data={bulkFiles}
        columns={columns}
        pageSize={pageSize}
        isLoading={isLoading}
        onPageSizeChange={handlePageSizeChange}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        onGoToNext={handleGotoNext}
        onGoToPrevious={handleGoToPrevious}
        pageIndex={1}
        showRecordsNumberForm={true}
        onSortColumn={handleSortData}
      />
    </PageWrapper>
  );
}
