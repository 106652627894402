/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalDeliveryRequestLifeCycleAdminQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type MedicalDeliveryRequestLifeCycleAdminQuery = { __typename?: 'Query', medicalDeliveryRequest?: { __typename: 'MedicalDeliveryRequest', id: string, status: Types.MedicalDeliveryRequestStatus, number?: string | null, failReason?: string | null, branch?: { __typename: 'Branch', id: string, name: string, nameAr?: string | null } | null, events?: Array<{ __typename: 'MedicalDeliveryEvent', id: string, date?: any | null, type?: Types.OrderEventsEnum | null } | null> | null } | null };


export const MedicalDeliveryRequestLifeCycleAdminDocument = gql`
    query medicalDeliveryRequestLifeCycleAdmin($id: ID!) {
  medicalDeliveryRequest(id: $id) {
    id
    status
    number
    failReason
    branch {
      id
      name
      nameAr
      __typename
    }
    branch {
      name
      nameAr
      __typename
    }
    number
    status
    events {
      id
      date
      type
      __typename
    }
    __typename
  }
}
    `;

/**
 * __useMedicalDeliveryRequestLifeCycleAdminQuery__
 *
 * To run a query within a React component, call `useMedicalDeliveryRequestLifeCycleAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useMedicalDeliveryRequestLifeCycleAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalDeliveryRequestLifeCycleAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMedicalDeliveryRequestLifeCycleAdminQuery(baseOptions: Apollo.QueryHookOptions<MedicalDeliveryRequestLifeCycleAdminQuery, MedicalDeliveryRequestLifeCycleAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MedicalDeliveryRequestLifeCycleAdminQuery, MedicalDeliveryRequestLifeCycleAdminQueryVariables>(MedicalDeliveryRequestLifeCycleAdminDocument, options);
      }
export function useMedicalDeliveryRequestLifeCycleAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MedicalDeliveryRequestLifeCycleAdminQuery, MedicalDeliveryRequestLifeCycleAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MedicalDeliveryRequestLifeCycleAdminQuery, MedicalDeliveryRequestLifeCycleAdminQueryVariables>(MedicalDeliveryRequestLifeCycleAdminDocument, options);
        }
export type MedicalDeliveryRequestLifeCycleAdminQueryHookResult = ReturnType<typeof useMedicalDeliveryRequestLifeCycleAdminQuery>;
export type MedicalDeliveryRequestLifeCycleAdminLazyQueryHookResult = ReturnType<typeof useMedicalDeliveryRequestLifeCycleAdminLazyQuery>;
export type MedicalDeliveryRequestLifeCycleAdminQueryResult = Apollo.QueryResult<MedicalDeliveryRequestLifeCycleAdminQuery, MedicalDeliveryRequestLifeCycleAdminQueryVariables>;