import { PrescriptionDispenseStatus } from "@health/queries";
import { Box, CircularProgress, EmptyCard, Typography } from "@health/ui";
import React, { FC } from "react";
import { useLifeCycleHooks } from "./LifeCycle.hook";
import { useLifeCycleStyles } from "./LifeCycle.styles";
import { LifeCycleDrawerComponents } from "./LifeCycleDrawer.components";
import { OrdersEvents } from "./OrdersEvents.components";

type LifeCycleModelProps = {
  orderId: string;
  referenceNumber: string;
  dispenseStatus: PrescriptionDispenseStatus;
};

export const LifeCycleModel: FC<LifeCycleModelProps> = ({ orderId, referenceNumber, dispenseStatus }) => {
  const { classes } = useLifeCycleStyles({ dispenseStatus });

  const { open, loading, order, deliveryWorkflowId, t, i18n, handleClickOpen, handleClickClose } = useLifeCycleHooks({ orderId });

  return (
    <LifeCycleDrawerComponents open={open} onClickOpen={handleClickOpen} onClickClose={handleClickClose}>
      <div className={classes.container} id='scrollableDiv'>
        <div className={classes.flex}>
          <Typography className={classes.title}>
            {t("eRx")}
            <span className={classes.referenceNumber}> {t(referenceNumber)} </span>
            {i18n.language === "en" && t("History")}
          </Typography>
        </div>

        {loading ? (
          <Box display='flex' alignItems='center' justifyContent='center' sx={{ mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {!order ? (
              <Box height='100%' display='flex' alignItems='center'>
                <EmptyCard title={t("There are no orders")} message={t("There are no orders related to this e-prescription yet")} />
              </Box>
            ) : (
              <>
                <Typography className={classes.textBold}>
                  {t("Order No")}: {order?.number}
                </Typography>

                <OrdersEvents order={order} dispenseStatus={dispenseStatus} deliveryWorkflowId={deliveryWorkflowId} />
              </>
            )}
          </>
        )}
      </div>
    </LifeCycleDrawerComponents>
  );
};
