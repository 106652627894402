/**
 * this type must be updated to have new variables
 * .env convention
 * VAR_NAME --> this is the only thing we add to this type assuming you are folloing the convention
 * REACT_APP_VAR_NAME=${VAR_NAME}
 * NEXT_PUPLIC_VAR_NAME=${VAR_NAME}
 *
 */
type ENV_VARIABLE =
  | "SERVER_URL"
  | "SUBSCRIPTION_URL"
  | "UPLOAD_SERVICE_URL"
  | "DOWNLOAD_SERVICE_URL"
  | "BASE_URL"
  | "APOLLO_SERVER_URL"
  | "BASE_MEDIA_URL"
  | "GOOGLE_API_KEY"
  | "IMAGES_DOMAIN_URL"
  | "ENABLE_VIRTUAL_PHARMACY"
  | "IS_MOBILE_APP_PUBLISHED"
  | "PRESCRIPTION_ORDER_DOWNLOAD"
  | "DOWNLOAD_MEDICAL_DELIVERY_REQUEST"
  | "UPLOAD_MEDICAL_DELIVERY_REQUEST"
  | "PRESCRIPTION_ORDER_UPLOAD";

const getEnvVariableBase = (prefix: string, pureName: string) => {
  const x = new Function("process", `return process.env.${pureName} || process.env.${prefix}_${pureName};`);
  return x(process) || process.env[`${prefix}_${pureName}`] || process.env[pureName];
};
const envsPrefix = ["", "BASE", "REACT_APP", "NEXT_PUBLIC"];

const getActiveEnvVariable = (pureName: string) => {
  return envsPrefix.map(prefix => getEnvVariableBase(prefix, pureName)).filter(Boolean)[0];
};

const getDefaultVariables = (): Record<ENV_VARIABLE, string> => {
  return {
    APOLLO_SERVER_URL:
      getActiveEnvVariable("APOLLO_SERVER_URL") || process.env.REACT_APP_APOLLO_SERVER_URL || process.env.NEXT_PUBLIC_APOLLO_SERVER_URL,
    GOOGLE_API_KEY:
      getActiveEnvVariable("GOOGLE_API_KEY") || process.env.REACT_APP_GOOGLE_API_KEY || process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
    IMAGES_DOMAIN_URL:
      getActiveEnvVariable("IMAGES_DOMAIN_URL") || process.env.REACT_APP_IMAGES_DOMAIN_URL || process.env.NEXT_PUBLIC_IMAGES_DOMAIN_URL,
    BASE_MEDIA_URL:
      getActiveEnvVariable("BASE_MEDIA_URL") || process.env.REACT_APP_BASE_MEDIA_URL || process.env.NEXT_PUBLIC_BASE_MEDIA_URL,
    SUBSCRIPTION_URL:
      getActiveEnvVariable("SUBSCRIPTION_URL") || process.env.REACT_APP_SUBSCRIPTION_URL || process.env.NEXT_PUBLIC_SUBSCRIPTION_URL,
    BASE_URL: getActiveEnvVariable("BASE_URL") || process.env.REACT_APP_BASE_URL || process.env.NEXT_PUBLIC_BASE_URL,
    DOWNLOAD_SERVICE_URL:
      getActiveEnvVariable("DOWNLOAD_SERVICE_URL") ||
      process.env.REACT_APP_DOWNLOAD_SERVICE_URL ||
      process.env.NEXT_PUBLIC_DOWNLOAD_SERVICE_URL,
    SERVER_URL: getActiveEnvVariable("SERVER_URL") || process.env.REACT_APP_SERVER_URL || process.env.NEXT_PUBLIC_SERVER_URL,
    ENABLE_VIRTUAL_PHARMACY:
      getActiveEnvVariable("ENABLE_VIRTUAL_PHARMACY") ||
      process.env.REACT_APP_ENABLE_VIRTUAL_PHARMACY ||
      process.env.NEXT_PUBLIC_ENABLE_VIRTUAL_PHARMACY,
    IS_MOBILE_APP_PUBLISHED:
      getActiveEnvVariable("IS_MOBILE_APP_PUBLISHED") ||
      process.env.REACT_APP_IS_MOBILE_APP_PUBLISHED ||
      process.env.NEXT_PUBLIC_IS_MOBILE_APP_PUBLISHED,
    UPLOAD_SERVICE_URL:
      getActiveEnvVariable("UPLOAD_SERVICE_URL") || process.env.REACT_APP_UPLOAD_SERVICE_URL || process.env.NEXT_PUBLIC_UPLOAD_SERVICE_URL,
    PRESCRIPTION_ORDER_DOWNLOAD: process.env.PRESCRIPTION_ORDER_DOWNLOAD,
    PRESCRIPTION_ORDER_UPLOAD: process.env.PRESCRIPTION_ORDER_UPLOAD,
    DOWNLOAD_MEDICAL_DELIVERY_REQUEST:
      getActiveEnvVariable("DOWNLOAD_MEDICAL_DELIVERY_REQUEST") || process.env.DOWNLOAD_MEDICAL_DELIVERY_REQUEST,
    UPLOAD_MEDICAL_DELIVERY_REQUEST: getActiveEnvVariable("UPLOAD_MEDICAL_DELIVERY_REQUEST") || process.env.UPLOAD_MEDICAL_DELIVERY_REQUEST,
  };
};
export const isInNextApp = () => {
  return !!process.env.NEXT_PUBLIC_APOLLO_SERVER_URL;
};

const getWindowRunTimeEnv = () => {
  const WASFATY_CONFIG_FUNCTION_NAME =
    process.env.NEXT_PUBLIC_WASFATY_CONFIG_FUNCTION_NAME || process.env.REACT_APP_WASFATY_CONFIG_FUNCTION_NAME;
  if (typeof globalThis[WASFATY_CONFIG_FUNCTION_NAME] === "undefined" && typeof window === "undefined") {
    return null;
  }
  if (typeof globalThis[WASFATY_CONFIG_FUNCTION_NAME] === "function" || typeof window[WASFATY_CONFIG_FUNCTION_NAME] === "function") {
    const getEncodedRuntimeEnv: () => string = globalThis[WASFATY_CONFIG_FUNCTION_NAME] || window[WASFATY_CONFIG_FUNCTION_NAME];
    return JSON.parse(Buffer.from(getEncodedRuntimeEnv(), "base64").toString());
  }
};

/**
 * this function must be used in the app were ever you need to read form .env, or unexpected behaviour might happen
 * please focus in review that no direct process.env.something exist any where or feature will not work in many envs
 *
 * @param baseKey  the key that you expect to have in .env/.env.js file.
 * @returns
 */
export const createGetEnvVariable = () => {
  const defaultVariables = getDefaultVariables();
  let runTimeVars = getWindowRunTimeEnv();
  return (baseKey: ENV_VARIABLE, fallBack?: string): string => {
    if (!runTimeVars) {
      runTimeVars = getWindowRunTimeEnv();
    }
    if (runTimeVars && runTimeVars?.[baseKey]?.trim()) {
      return runTimeVars?.[baseKey]?.trim() || fallBack;
    }
    if (defaultVariables?.[baseKey]) return defaultVariables?.[baseKey] || fallBack;
    else if (getActiveEnvVariable(baseKey)) {
      return getActiveEnvVariable(baseKey) || fallBack;
    } else {
      if (fallBack) return fallBack;
      console.error({ seriousError: `env variable: ${baseKey} was not found`, env: process.env });
      console.log({ seriousError: `env variable: ${baseKey} was not found` });
      return "";
    }
  };
};

export const getEnvVariable = createGetEnvVariable();
