// import { arEG } from "@mui/material/locale";
import { i18n } from "@health/i18n";
import { createTheme } from "@mui/material/styles";
import { CustomComponentsOverride } from "./components";
import { CustomThemePalette } from "./palette";

declare module "@mui/material/styles" {
  interface Palette {
    darkGrey: Palette["primary"];
  }
  interface PaletteOptions {
    darkGrey: PaletteOptions["primary"];
  }
}

const fontFamilies = [
  // "Airbnb Cereal App Book",
  // "Airbnb Cereal App Medium",
  // "Airbnb Cereal App Light",
  // "Airbnb Cereal App Bold",
  // "Airbnb Cereal App Extra Bold",
  // "Airbnb Cereal App Black",
  "CoHeadlineTrial-Light",
  "CoHeadlineTrial-Regular",
  "CoHeadlineTrial-Bold",
  "Arab Kufi Regular",
  "Arab Kufi Medium",
  "Arab Kufi Bold",
].join(",");

const CustomTheme = createTheme(
  {
    direction: i18n.dir(i18n.language),
    palette: CustomThemePalette,
    components: CustomComponentsOverride,
    typography: {
      fontFamily: fontFamilies,
      allVariants: {
        fontFamily: fontFamilies,
      },
    },
    mixins: {
      toolbar: {
        minHeight: 70,
      },
    },
  }
  // arEG
);

CustomTheme.shadows[0] = "none";
/*
  Card BoxShadow
*/
CustomTheme.shadows[1] = "0px 0px 10px #00000029";
/*
  Default BoxShadow
*/
CustomTheme.shadows[2] = "0px 0px 20px #0000001A";

export { CustomTheme };
