/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { LanguageDisplayFragmentDoc } from '../../../../fragment/languageDisplay/__generated__/languageDisplay';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MedicalDeliveryRequestsCreateMutationVariables = Types.Exact<{
  input: Array<Types.MedicalDeliveryRequestInput> | Types.MedicalDeliveryRequestInput;
}>;


export type MedicalDeliveryRequestsCreateMutation = { __typename?: 'Mutation', medicalDeliveryRequestsCreate?: { __typename?: 'MedicalDeliveryRequestsCreate', medicalDeliveryRequests?: Array<{ __typename?: 'MedicalDeliveryRequest', id: string, created: any, number?: string | null, description?: string | null, carrierName?: string | null, temperature?: string | null, requiredDeliveryTime?: number | null, dropOffContactName?: string | null, dropOffPhoneNumber?: string | null, deliveryStatus: Types.MedicalDeliveryRequestDeliveryStatus, dropOffAddress: { __typename?: 'OrderAddress', id: string, name?: string | null, area?: string | null, streetAddress1?: string | null, streetAddress2?: string | null, buildingNumber?: string | null, city?: { __typename?: 'City', name: string, nameAr?: string | null } | null, translations?: Array<{ __typename?: 'OrderAddressTranslation', name?: string | null, streetAddress1: string, streetAddress2: string, language: { __typename?: 'LanguageDisplay', code: Types.LanguageCodeEnum, language: string } } | null> | null, coordinates?: { __typename?: 'LocationType', lat?: number | null, lng?: number | null } | null }, branch?: { __typename?: 'Branch', name: string, vendor: { __typename?: 'Vendor', name: string, nameAr?: string | null } } | null }> | null, deliveryErrors: Array<{ __typename?: 'DeliveryError', code: Types.DeliveryErrorCode, field?: string | null, message?: string | null }> } | null };


export const MedicalDeliveryRequestsCreateDocument = gql`
    mutation medicalDeliveryRequestsCreate($input: [MedicalDeliveryRequestInput!]!) {
  medicalDeliveryRequestsCreate(input: $input) {
    medicalDeliveryRequests {
      id
      created
      number
      description
      carrierName
      temperature
      requiredDeliveryTime
      dropOffContactName
      dropOffPhoneNumber
      dropOffAddress {
        id
        name
        area
        streetAddress1
        streetAddress2
        buildingNumber
        city {
          name
          nameAr
        }
        translations {
          name
          streetAddress1
          streetAddress2
          language {
            ...languageDisplay
          }
        }
        coordinates {
          lat
          lng
        }
      }
      deliveryStatus
      branch {
        name
        vendor {
          name
          nameAr
        }
      }
    }
    deliveryErrors {
      code
      field
      message
    }
  }
}
    ${LanguageDisplayFragmentDoc}`;
export type MedicalDeliveryRequestsCreateMutationFn = Apollo.MutationFunction<MedicalDeliveryRequestsCreateMutation, MedicalDeliveryRequestsCreateMutationVariables>;

/**
 * __useMedicalDeliveryRequestsCreateMutation__
 *
 * To run a mutation, you first call `useMedicalDeliveryRequestsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMedicalDeliveryRequestsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [medicalDeliveryRequestsCreateMutation, { data, loading, error }] = useMedicalDeliveryRequestsCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMedicalDeliveryRequestsCreateMutation(baseOptions?: Apollo.MutationHookOptions<MedicalDeliveryRequestsCreateMutation, MedicalDeliveryRequestsCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MedicalDeliveryRequestsCreateMutation, MedicalDeliveryRequestsCreateMutationVariables>(MedicalDeliveryRequestsCreateDocument, options);
      }
export type MedicalDeliveryRequestsCreateMutationHookResult = ReturnType<typeof useMedicalDeliveryRequestsCreateMutation>;
export type MedicalDeliveryRequestsCreateMutationResult = Apollo.MutationResult<MedicalDeliveryRequestsCreateMutation>;
export type MedicalDeliveryRequestsCreateMutationOptions = Apollo.BaseMutationOptions<MedicalDeliveryRequestsCreateMutation, MedicalDeliveryRequestsCreateMutationVariables>;