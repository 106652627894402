import { useTranslation } from "@health/i18n";
import { FieldsTypes, FilterField } from "@health/ui";

const processingStatusOptions = ["Pending", "Processed", "Failed", "Cancelled", "UnderProcess"];

export const useFileListDetailsFilter = (activeFilters: any) => {
  const { t } = useTranslation("Admin");

  const filters: FilterField[] = [
    {
      id: "created",
      grid: 6,
      name: "created",
      type: FieldsTypes.date,
      fieldProps: {
        placeholder: t("Created Date From"),
        label: t("Created Date From"),
        name: "created",
        defaultValue: activeFilters?.created,
      },
    },
    {
      id: "createdTo",
      grid: 6,
      name: "createdTo",
      type: FieldsTypes.date,
      fieldProps: {
        placeholder: t("Created Date To"),
        label: t("Created Date To"),
        name: "createdTo",
        defaultValue: activeFilters?.createdTo,
      },
    },
    {
      id: "updated",
      grid: 6,
      name: "updated",
      type: FieldsTypes.date,
      fieldProps: {
        placeholder: t("Update Date From"),
        label: t("Update Date From"),
        name: "updated",
        defaultValue: activeFilters?.updated,
      },
    },
    {
      id: "updatedTo",
      grid: 6,
      name: "updatedTo",
      type: FieldsTypes.date,
      fieldProps: {
        placeholder: t("Updated Date To"),
        label: t("Updated Date To"),
        name: "updatedTo",
        defaultValue: activeFilters?.updatedTo,
      },
    },
    {
      id: "processingStatus",
      grid: 6,
      name: "processingStatus",
      type: FieldsTypes.lazyAutocomplete,
      fieldProps: {
        placeholder: t("Processing Status"),
        name: "processingStatus",
        options: processingStatusOptions,
        getOptionLabel: o => t(o.toString().toUpperCase()),
        defaultValue: activeFilters?.processingStatus,
        onFetchMore: () => {},
      },
    },
  ];

  return { filters };
};
