/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrderStatusForceUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.OrderStatusUpdateInput;
}>;


export type OrderStatusForceUpdateMutation = { __typename?: 'Mutation', orderStatusForceUpdate?: { __typename?: 'OrderStatusForceUpdate', errors: Array<{ __typename?: 'Error', field?: string | null, message?: string | null }>, orderErrors: Array<{ __typename?: 'OrderError', field?: string | null, message?: string | null, code: Types.OrderErrorCode, branch?: string | null, orderLine?: string | null }> } | null };


export const OrderStatusForceUpdateDocument = gql`
    mutation OrderStatusForceUpdate($id: ID!, $input: OrderStatusUpdateInput!) {
  orderStatusForceUpdate(id: $id, input: $input) {
    errors {
      field
      message
    }
    orderErrors {
      field
      message
      code
      branch
      orderLine
    }
  }
}
    `;
export type OrderStatusForceUpdateMutationFn = Apollo.MutationFunction<OrderStatusForceUpdateMutation, OrderStatusForceUpdateMutationVariables>;

/**
 * __useOrderStatusForceUpdateMutation__
 *
 * To run a mutation, you first call `useOrderStatusForceUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderStatusForceUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderStatusForceUpdateMutation, { data, loading, error }] = useOrderStatusForceUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOrderStatusForceUpdateMutation(baseOptions?: Apollo.MutationHookOptions<OrderStatusForceUpdateMutation, OrderStatusForceUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderStatusForceUpdateMutation, OrderStatusForceUpdateMutationVariables>(OrderStatusForceUpdateDocument, options);
      }
export type OrderStatusForceUpdateMutationHookResult = ReturnType<typeof useOrderStatusForceUpdateMutation>;
export type OrderStatusForceUpdateMutationResult = Apollo.MutationResult<OrderStatusForceUpdateMutation>;
export type OrderStatusForceUpdateMutationOptions = Apollo.BaseMutationOptions<OrderStatusForceUpdateMutation, OrderStatusForceUpdateMutationVariables>;