import { makeStyles } from "tss-react/mui";

export const useCustomMuiDialogStyle = makeStyles()({
  header: {
    display: "flex",
    flex: 1,
    height: "50px",
    padding: "15px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actions: {
    justifyContent: "flex-start",
    padding: "15px",
    boxShadow: " 0px 0px 6px #0000001A",
    borderRadius: "0px 0px 15px 15px",
  },
  divider: {
    margin: "0px 20px",
  },
  title: {
    fontSize: 16,
    fontFamily: "CoHeadlineTrial-Light",
  },
  arabicTitle: {
    fontFamily: "Arab Kufi Medium",
  },
});
