import { getEnvVariable, UserContext } from "@health/common";
import { useTranslation } from "@health/i18n";
import { useDownloadBulkFileLazyQuery } from "@health/queries";
import { StyledButton, UploadIcon } from "@health/ui";
import { useContext, useEffect, useState } from "react";

export const DownloadfileButton = ({ fileId }: { fileId: string | null | undefined }) => {
  const { t } = useTranslation("Admin");
  const [isDownloading, setIsDownloading] = useState(false);
  const { token } = useContext(UserContext);

  const [downloadBulkFile, { data: downloadLink, loading }] = useDownloadBulkFileLazyQuery({
    variables: {
      fileId: fileId ? fileId : "",
    },
  });

  useEffect(() => {
    if (downloadLink?.downloadBulkFile) {
      handleDownloadTemplate(`${getEnvVariable("SERVER_URL")}/${downloadLink.downloadBulkFile}`, "prescription_orders.xlsx");
    }
  }, [downloadLink]);

  const handleDownloadTemplate = (templateDownloadLink, templateDownloadFileName) => {
    setIsDownloading(true);
    fetch(templateDownloadLink!, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", templateDownloadFileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        setIsDownloading(false);
      })
      .catch(error => {
        console.error("Error fetching template:", error);
        setIsDownloading(false);
      });
  };

  const handleDownloadClick = () => {
    if (fileId) {
      downloadBulkFile({
        variables: {
          fileId: fileId,
        },
      });
    }
    if (downloadLink?.downloadBulkFile) {
      handleDownloadTemplate(`https://api.dev2.wasfatyplus.com/${downloadLink.downloadBulkFile}`, "prescription_orders.xlsx");
    }
  };
  return (
    <StyledButton
      onClick={handleDownloadClick}
      color='success'
      size='medium'
      type='submit'
      startIcon={<UploadIcon color='inherit' transform='rotate(180)' />}
      disabled={isDownloading}
    >
      {isDownloading || loading ? t("Downloading") : t("Download")}
    </StyledButton>
  );
};
