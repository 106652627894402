/* eslint-disable */
/* prettier-ignore */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetOrderStatusForceUpdateLogsQueryVariables = Types.Exact<{
  orderId: Types.Scalars['ID'];
}>;


export type GetOrderStatusForceUpdateLogsQuery = { __typename?: 'Query', getOrderStatusForceUpdateLogs?: Array<{ __typename?: 'OrderStatusChangeLogType', oldStatus?: string | null, newStatus?: string | null, id: string, dateTime?: any | null, orderId?: string | null, userId?: number | null } | null> | null };


export const GetOrderStatusForceUpdateLogsDocument = gql`
    query GetOrderStatusForceUpdateLogs($orderId: ID!) {
  getOrderStatusForceUpdateLogs(orderId: $orderId) {
    oldStatus
    newStatus
    id
    dateTime
    orderId
    userId
  }
}
    `;

/**
 * __useGetOrderStatusForceUpdateLogsQuery__
 *
 * To run a query within a React component, call `useGetOrderStatusForceUpdateLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderStatusForceUpdateLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderStatusForceUpdateLogsQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderStatusForceUpdateLogsQuery(baseOptions: Apollo.QueryHookOptions<GetOrderStatusForceUpdateLogsQuery, GetOrderStatusForceUpdateLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderStatusForceUpdateLogsQuery, GetOrderStatusForceUpdateLogsQueryVariables>(GetOrderStatusForceUpdateLogsDocument, options);
      }
export function useGetOrderStatusForceUpdateLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderStatusForceUpdateLogsQuery, GetOrderStatusForceUpdateLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderStatusForceUpdateLogsQuery, GetOrderStatusForceUpdateLogsQueryVariables>(GetOrderStatusForceUpdateLogsDocument, options);
        }
export type GetOrderStatusForceUpdateLogsQueryHookResult = ReturnType<typeof useGetOrderStatusForceUpdateLogsQuery>;
export type GetOrderStatusForceUpdateLogsLazyQueryHookResult = ReturnType<typeof useGetOrderStatusForceUpdateLogsLazyQuery>;
export type GetOrderStatusForceUpdateLogsQueryResult = Apollo.QueryResult<GetOrderStatusForceUpdateLogsQuery, GetOrderStatusForceUpdateLogsQueryVariables>;