import { Box, Button, Card, Typography } from "@mui/material";
import React, { FC } from "react";
import { RoutingErrorPageProps } from "./routingErrorPage.type";

export const RoutingErrorPage: FC<RoutingErrorPageProps> = ({
  descriptionText,
  img,
  actionButton1: handleClickActionButton1,
  actionButton2: handleClickActionButton2,
  buttonText1,
  buttonText2,
}) => {
  return (
    <Card sx={{ padding: "60px" }}>
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        {img}
      </Box>

      <Typography
        textAlign='center'
        sx={{
          paddingTop: "9px",
          fontSize: 22,
          fontFamily: "CoHeadlineTrial-Light",
        }}
      >
        {descriptionText}
      </Typography>

      <Box display='flex' justifyContent='center' marginTop='20px'>
        <Button onClick={handleClickActionButton1}>{buttonText1}</Button>

        {buttonText2 && handleClickActionButton2 && (
          <Button onClick={handleClickActionButton2} variant='outlined'>
            {buttonText2}
          </Button>
        )}
      </Box>
    </Card>
  );
};
