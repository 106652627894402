// Primary Colors
export const darkBlue = "#1C2346";
export const darkGrey = "#5b7798";
export const lightGrey = "#F2F4F7";
export const greyVendor = "#607795";

// Secondary Colors
export const orange = "#E05729";
export const success = "#5FA02E";
export const darkRed = "#973149";
export const green = "#46A200";
export const cyan = "#E06E0E";
export const yellow = "#f1c502";

// Grey Colors
export const tablesRawsGrey = "#FAFBFD";
export const bodyBackgroundGrey = "#ECF0F5";
export const bordersGrey = "#DFE8F2";
export const tableRowsBorder = "#D3DDEA";
export const tableOutlineBorder = "#CBD5E1";

export const turquoise = "#0E6973";
export const white = "#FFFFFF";
export const whiteGreen = "#FAFFFF";
export const black = "#000000";

export const inputBorderColorHover = "#617695";
export const inputBorderColorDefault = "#E1E8F1";

export const text = "#1C2444";

export const primary = darkBlue;
export const grey = "#ccc";
export const bg = "#F0F3F7";

export const tealBlue = "#15BFB7";
